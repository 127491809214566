import styled from 'styled-components'
import { Wrapper } from '@dayancloud/common/components'
import { color, Media, typography } from '@dayancloud/common/theme'
import Bannerbg from '../images/download/bannerbg.png'
import BannerbgMobile from '../images/download/bannerbg_mobile.png'

export const Container = styled.div`
  background-color: #fff;
  padding-bottom: 95px;
  ${Media.lessThan(Media.small)} {
    padding-bottom: 40px;
  }
`
export const BannerContainer = styled(Wrapper)`
  position: relative;
  height: 630px;
  z-index: 0;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  background: url(${Bannerbg});
  max-width: 100%;
  padding-top: 62px;
  background-size: 100% 100%;
  ${Media.lessThan(Media.small)} {
    height: 650px;
    background: url(${BannerbgMobile});
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 0;
    background-size: 100% 100%;
  }
`
export const BannerRight = styled.div`
  width: 566px;
  height: 400px;
  margin-right: -156px;
  img {
    width: 100%;
    height: 100%;
  }
  ${Media.lessThan(Media.small)} {
    width: 360px;
    height: auto;
    margin: 0;
  }
`
export const BannerLeft = styled.div`
  text-align: left;
  margin-right: 90px;
  color: #828995;
  font-size: ${typography.textSmall};
  flex-shrink: 0;
  padding-left: 42px;
  .title {
    width: 92px;
    height: 38px;
    line-height: 38px;
    text-align: center;
    background: rgb(107, 108, 255, 0.1);
    border-radius: 4px;
    span {
      color: ${color.prompt};
      font-size: 20px;
    }
  }
  .name {
    margin: 8px 0 14px;
    width: 460px;
    font-size: ${typography.h1};
    color: #2f323e;
    font-weight: bold;
    span {
      color: #000;
    }
  }
  .client-info {
    line-height: 26px;
    opacity: 0.7;
  }
  a {
    height: 44px;
    line-height: 44px;
    width: 160px;
    font-size: ${typography.h4};
    margin-top: 40px;
    border-radius: 4px;
  }
  /* .new-down {
    margin: 0 24px;
  } */
  .pdf {
    width: 200px;
  }
  ${Media.lessThan(Media.small)} {
    width: 100%;
    flex-shrink: 1;
    margin: 60px 0 0;
    font-size: ${typography.textSmall};
    text-align: center;
    padding-left: 0;
    .title {
      width: 78px;
      height: 32px;
      line-height: 32px;
      margin: auto;
      border-radius: 2px;
      span {
        font-size: 17px;
      }
    }
    .name {
      width: auto;
      font-size: 36px;
      margin-bottom: 10px;
    }
    .client-info {
      font-size: 14px;
      opacity: 0.7;
    }
    a {
      font-size: ${typography.text};
      font-weight: 500;
      margin: 27px 0;
      height: 40px;
      line-height: 40px;
      width: 150px;
      border-radius: 2px;
    }
  }
`

export const TeachContainer = styled.div`
  margin-top: 90px;
  background-color: #fff;
  text-align: center;
  h2 {
    font-weight: 500;
    font-size: 36px;
    color: #2f323e;
    line-height: 24px;
    margin-bottom: 60px;
  }
  p {
    margin: 50px 0 0;
    font-family: 'SourceHanSansCN-Medium';
    font-size: ${typography.text};
    color: #828995;
    line-height: 24px;
    a {
      color: ${color.prompt};
    }
  }
  ${Media.lessThan(Media.small)} {
    margin-top: 40px;
    h2 {
      font-size: 25px;
      margin-bottom: 26px;
    }
    p {
      margin-top: 22px;
      font-size: 14px;
      padding: 0 19px;
    }
  }
`
export const VideoContainer = styled.div`
  width: 938px;
  height: 528px;
  margin: auto;
  border: 1px solid #e8e8e8;
  box-shadow: 0px 0px 20px 0px rgba(181, 181, 181, 0.2);
  border-radius: 10px;
  overflow: hidden;
  video {
    width: 938px;
    height: 528px;
    object-fit: fill;
  }
  ${Media.lessThan(Media.small)} {
    width: 345px;
    height: 194px;
    video {
      width: 345px;
      height: 194px;
    }
  }
`
