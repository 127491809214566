import React from 'react'
import styled from 'styled-components'
import { Button } from '../../../../common/components'
import { color, typography, Media } from '../../../../common/theme'
import TryImage from '../../images/index/try.png'
import { API } from '../../../../common/utils'
import { onAuthEntry } from '../../../../common/service'

const TryBannerWrapper = styled.div`
  height: 164px;
  background: url(${TryImage});
  color: white;
  background-size: 100% 100%;
  ${Media.lessThan(Media.small)} {
    height: 155px;
    background: ${color.prompt};
  }
`
const TryBannerContainer = styled.div`
  margin: 0 auto;
  height: 100%;
  max-width: 1200px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  h3 {
    font-size: 26px;
    line-height: 1;
    font-weight: 500;
  }
  a {
    font-size: ${typography.h4};
    margin-left: 250px;
    font-weight: 500;
    border-radius: 4px;
    width: 160px;
  }
  ${Media.lessThan(Media.small)} {
    width: 100%;
    height: 150px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    h3 {
      font-size: 19px;
      width: 295px;
      line-height: 28px;
      margin: 0 auto;
      text-align: center;
    }
    a {
      width: 150px;
      height: 35px;
      display: flex;
      align-items: center;
      justify-content: center;
      margin: 19px auto 0;
      border-radius: 3px;
    }
  }
`
class TryBanner extends React.PureComponent {
  render() {
    return (
      <TryBannerWrapper>
        <TryBannerContainer>
          <h3>马上体验大雁云实景三维重建云服务，一键带您进入光速云重建时代</h3>
          <Button
            variant='outlined'
            id='ljty-footer'
            as='a'
            rel='nofollow'
            onClick={() => onAuthEntry(`${API.auth.host}/sso`)}
          >
            立即体验
          </Button>
        </TryBannerContainer>
      </TryBannerWrapper>
    )
  }
}

export default TryBanner
