import React from 'react'
import axios from 'axios'
import { API } from '@dayancloud/common/utils'
import { SEO, Button, Layout } from '@dayancloud/common/components'
import {
  Container,
  BannerContainer,
  BannerLeft,
  BannerRight,
  TeachContainer,
  VideoContainer,
} from './download.atom'
import TryBanner from '../molecules/index/try-banner'

import bannerImg from '../images/download/banner.png'

class Download extends React.PureComponent {
  state = {
    windowsVersion: '',
    windowsDate: '',
    windowsDownloadLink: '',
    newWindowsDownloadLink: '',
  }
  async getClientInfo() {
    const instance = axios.create({
      baseURL: API.auth.downloadHost,
    })
    const windowsClient = await instance.get(API.auth.windowsVersion)
    const newWindowsClient = await instance.get(API.auth.newWindowsVersion)
    if (windowsClient.status === 200) {
      const windowsData = windowsClient.data
      const windowsUpdateTime = windowsData.update_time.split('-')
      this.setState({
        windowsVersion: windowsData.version,
        windowsDate: `${windowsUpdateTime[0]}年${windowsUpdateTime[1]}月${windowsUpdateTime[2]}日`,
        windowsDownloadLink: windowsData.download_url,
      })
    }
    if (newWindowsClient.status === 200) {
      this.setState({
        newWindowsDownloadLink: newWindowsClient.data.download_url,
      })
    }
  }
  componentDidMount() {
    this.getClientInfo()
  }
  render() {
    const {
      location,
      pageContext: { topFiveNews },
    } = this.props
    const { windowsDate, newWindowsDownloadLink } = this.state
    return (
      <Layout location={location} topFiveNews={topFiveNews}>
        <SEO
          title='实景三维建模软件|实景三维云处理客户端|三维重建软件免费下载-大雁云'
          keywords='无人机3d建模软件,三维实景建模软件,云端建模工具,航拍三维建模软件'
          description='大雁云实景三维重建平台提供无人机倾斜摄影3d建模软件免费下载，基于海量机器运算力的云端建模工具，快速生成三维模型，大大提升您的三维实景重建效率。'
          ogUrl='https://www.dayancloud.com/download.html'
          ogType='website'
        />
        <Container>
          <BannerContainer>
            <BannerLeft>
              <div className='title'>
                <span>大雁云</span>
              </div>
              <h1 className='name'>实景三维重建客户端</h1>
              {/* <div className='client-info'>客户端版本：{windowsVersion}</div> */}
              <div className='client-info'>系统：Windows7及以上版本</div>
              <div className='client-info'>更新日期：{windowsDate}</div>
              {/* <Button
                as='a'
                className='down'
                href={windowsDownloadLink}
                id='ljxz'
                rel='nofollow noindex'
              >
                专业版下载
              </Button> */}
              <Button
                as='a'
                className='new-down'
                href={newWindowsDownloadLink}
                id='ljxz'
                rel='nofollow noindex'
              >
                立即下载
              </Button>
              {/* <Button
                className='pdf'
                as='a'
                download
                target='_blank'
                href='/大雁云客户端产品使用文档.pdf'
              >
                下载产品使用手册
              </Button> */}
            </BannerLeft>
            <BannerRight>
              <img src={bannerImg} alt='' />
            </BannerRight>
          </BannerContainer>
          <TeachContainer>
            <h2>影像数据提交教程</h2>
            <VideoContainer>
              <video
                src='//videos.ctfassets.net/e71quisb27o5/3Yv8GDKGydJ0ONlpjDtale/527595dd0ae1459535b58de69e728fe3/__________________.mp4'
                allowFullScreen={true}
                controls={true}
                autoPlay={false}
                ref={this.setVideoRef}
              />
            </VideoContainer>
            <p>
              关于影像数据提交大雁云实景三维重建客户端更多详细步骤说明，可访问{' '}
              <a href='https://www.dayancloud.com/support/new-work001/'>帮助中心</a> 查看
            </p>
          </TeachContainer>
          <TeachContainer>
            <h2>区块数据提交教程</h2>
            <VideoContainer>
              <video
                src='//videos.ctfassets.net/e71quisb27o5/3EvV4tC6rawWiU2cl57dlG/10baa61100e7594ce58e6ac77106fd1d/__________________.mp4'
                allowFullScreen={true}
                controls={true}
                autoPlay={false}
                ref={this.setVideoRef}
              />
            </VideoContainer>
            <p>
              关于区块数据提交大雁云实景三维重建客户端更多详细步骤说明，可访问{' '}
              <a href='https://www.dayancloud.com/support/new_bolck/'>帮助中心</a> 查看
            </p>
          </TeachContainer>
        </Container>
        <TryBanner />
      </Layout>
    )
  }
}

export default Download
